import { FUELS_UPDATE } from '../store/fuels/fuels.actions';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginatedList } from '@models/common/paginated-list.class';
import { Fuel } from '@models/fuel/fuel.class';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { FUELS_SET } from '../store/fuels/fuels.actions';
import { Store } from '@ngrx/store';
import { SnackbarService } from '../services/snackbar.service';

@Injectable()
export class FuelService {
  private uri: string = `${environment.routes.apiEndpoint}/fuel`;
  constructor(
    private http: HttpClient,
    private snackbarService: SnackbarService,
    private store: Store
  ) { }

  public getFuel(fuelId: string): Observable<Fuel> {
    return this.http.get<Fuel>(`${this.uri}/fuels/${fuelId}`);
  }

  public async postFuel(fuel: Fuel, showResultSnackbar: boolean = false): Promise<void> {
    this.http.post<string>(`${this.uri}/fuels`, fuel).subscribe({
      next: (resultId: string) => {
        fuel.id = resultId;
        this.store.dispatch(FUELS_UPDATE({ fuel: fuel }));
        if (showResultSnackbar) this.snackbarService.open('fuel.messages.created', 'success');
      },
      error: () => {
        if (showResultSnackbar) this.snackbarService.open('fuel.messages.cannotSave', 'error');
      }
    })
  }

  public async updateFuel(fuel: Fuel, showResultSnackbar: boolean = false): Promise<void> {
    this.http.put(`${this.uri}/fuels/${fuel.id}`, fuel).subscribe({
      next: () => {
        this.store.dispatch(FUELS_UPDATE({ fuel: fuel }));
        if (showResultSnackbar) this.snackbarService.open('fuel.messages.updated', 'success');
      },
      error: () => {
        if (showResultSnackbar) this.snackbarService.open('fuel.messages.cannotSave', 'error');
      }
    })
  }

  public async getAllFuels(): Promise<void> {
    this.http.get<PaginatedList<Fuel>>(`${this.uri}/fuels?PageSize=50`).subscribe(
      (fuels: PaginatedList<Fuel>) => {
        this.store.dispatch(FUELS_SET({ fuels: fuels.items }));
      });
  }

  public getFuels() {
    return this.http.get<PaginatedList<Fuel>>(`${this.uri}/fuels?PageSize=50`);
  }

  public deleteFuel(fuel: Fuel) {
    return this.http.delete(`${this.uri}/fuels/${fuel.id}`);
  }
}
